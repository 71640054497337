import Vue from 'vue'
import VueRouter from 'vue-router'
import Login_tpl from '../templates/Login.vue'
import Application_tpl from '../templates/Application.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: '/auth/login'
},
{
    path: '/auth',
    name: 'Auth',
    component: Login_tpl,
    children: [{
        path: 'login',
        name: 'Login',
        component: () =>
            import('@/views/auth/Login.vue')
    },
    {
        path: 'signup',
        name: 'Signup',
        component: () =>
            import('@/views/auth/Signup.vue')
    },
    {
        path: 'forgot-password',
        name: 'forgot-password',
        component: () =>
            import('@/views/auth/Forgot-password.vue')
    }
    ]
},
{
    path: '/hp',
    beforeEnter: _beforeEnter,
    name: 'Home Page',
    component: Application_tpl,
    children: [{
        path: 'dashboard',
        name: 'Dashboard',
        component: () =>
            import('@/views/hp/Home.vue')
    },
    {
        path: 'embedded',
        name: 'Embedded',
        component: () =>
            import('@/views/hp/Dashboard.vue')
    }
    ]
},
{
    path: '/compliance/pj',
    name: 'Compliance pj',
    beforeEnter: _beforeEnter,
    component: Application_tpl,
    children: [{
        name: 'Group Analyse',
        path: 'analyse/:cnpj',
        component: () =>
            import('@/views/compliance/rf/Analyses.vue')
    }]
},
{
    path: '/compliance/rf',
    beforeEnter: _beforeEnter,
    name: 'Compliance rf',
    component: Application_tpl,
    children: [{
        name: 'Group Analyse',
        path: 'analyse/:cnpj',
        component: () =>
            import('@/views/compliance/rf/Analyses.vue')
    }]
},
{
    path: '/clients',
    name: 'Clients Admin',
    component: Application_tpl,
    children: [{
        name: 'List all clients',
        path: '/clients',
        component: () =>
            import('@/views/clients/Clients-lists.vue')
    },
    {
        name: 'profile one client',
        path: '/clients/:client_id',
        component: () =>
            import('@/views/clients/Clients-profile.vue')
    },
    {
        name: 'profile User',
        path: '/clients/:client_id/users/:user_id',
        component: () =>
            import('@/views/clients/Users-profile.vue')
    }
    ]
},
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

function _beforeEnter(to, from, next) {
    var hasSession = localStorage.getItem('vue-session-key');

    if (hasSession) {
        next();
        return
    } else {
        next('/');
    }
}

export default router