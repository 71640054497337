import Vue from 'vue'
import Buefy from 'buefy'
import App from './App.vue'
import router from './router'
// import VueTheMask from 'vue-the-mask'
import * as firebase from "firebase/app";
import 'firebase/analytics';
import 'buefy/dist/buefy.css'
import VueLodash from 'vue-lodash'
import VueSession from 'vue-session'
import lodash from 'lodash'
import VueMask from 'v-mask'

import * as VueGoogleMaps from "vue2-google-maps"


const moment = require('moment')
require('moment/locale/es')

var firebaseConfig = {
    apiKey: "AIzaSyCyvI8emJY5MDaQ5gBaYiHAGsCeE_BG6DE",
    authDomain: "aura-due.firebaseapp.com",
    projectId: "aura-due",
    storageBucket: "aura-due.appspot.com",
    messagingSenderId: "309115424707",
    appId: "1:309115424707:web:aa2ec91f889c3fe5ce3776",
    measurementId: "G-P8J8HBMNB5"
};

//homolog

// const firebaseConfig = {
//     apiKey: "AIzaSyBUBewnK7fOyxqXLBNNSyvNG2lhwE189ok",
//     authDomain: "homolog-aura-duo.firebaseapp.com",
//     projectId: "homolog-aura-duo",
//     storageBucket: "homolog-aura-duo.appspot.com",
//     messagingSenderId: "10606511999",
//     appId: "1:10606511999:web:ef80322946d22060ff7c4a",
//     measurementId: "G-WVYNRM2Z0Q"
// };

firebase.initializeApp(firebaseConfig);
// firebase.initializeApp();
// firebase.analytics();

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')


Vue.use(Buefy)
// Vue.use(VueTheMask)
Vue.use(VueMask);
var options = {
    persist: true
}

Vue.use(VueSession, options)
Vue.use(require('vue-moment'), {
    moment
});
Vue.use(VueLodash, { lodash: lodash })

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyAbEoWWiuSbI-Kp9Zdpbp395iy7jFNdXdo",
        installComponents: true
    }
});